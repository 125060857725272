import React from 'react';
import Rainbow from './Rainbow';
import Home from './Home';
import Page from './Page';

import pages from './pages'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.css';

class App extends React.Component {

  render () {
    return (
      <div className="App">
        <Router>
          <Switch>
            {
              pages.map(page => (
                <Route key={page.url + '-route'} exact path={page.url}>
                  <Page key={page.url + '-page'} path={page.url} pages={pages} content={page}/>
                </Route>
              ))
            }
            <Route path="/">
              <Home pages={pages} />
            </Route>
          </Switch>
          <Rainbow about={pages.find(page => page.name === 'About')} gods={pages.filter(page => page.type === 'god')  } />
        </Router>
      </div>
    );
  }
}

export default App;
