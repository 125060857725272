import React from 'react';
import { Link } from 'react-router-dom';
import GodIcon from './GodIcon';

import './Rainbow.css'

class Rainbow extends React.Component {

    render() {
        return <div className='Rainbow'>
            <Link to='/' className='Rainbow-link Rainbow-home'><img width='64' height='64' src={this.props.about.icon} alt='Home' /></Link>
            {this.props.gods.map(god => <GodIcon key={god.url + '-icon'} god={god}></GodIcon>)}
            <Link to={this.props.about.url} className='Rainbow-link'><img width='64' height='64' src={this.props.about.icon} alt={this.props.about.name} /></Link>
        </div>
    }
}

export default Rainbow;