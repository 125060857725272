import React from 'react';
import './Home.css';
import Announcements from './Announcements'
import {Helmet} from "react-helmet";

class Home extends React.Component {
    render() {
        return (
            <header className="Home">
                <Helmet>
                    <meta
                    name="description"
                    content="A psychedelic cult dedicated to discovering the secret of the universe"
                    />
                    <meta property="og:image" content="http://theultimatecomment.com/logo512.png"></meta>
                </Helmet>
                <img src='/image/logo.png' className="Home-logo" alt="logo" />
                <p>
                <span className="Home-secret">There's an infinite number of secrets</span>
                </p>
                <Announcements announcements={this.props.pages.filter(page => page.type === 'announcement')} />
            </header>
        )
    }
}

export default Home;