import React from 'react';
import './GodIcon.css';
import { Link } from "react-router-dom";

class GodIcon extends React.Component {
    render() {
        return (
            <div className='GodIcon'>
                <Link to={this.props.god.url}><img width="64" height="64" alt={this.props.god.name} src={this.props.god.icon} /></Link>
            </div>
        )
    }
}

export default GodIcon