import React, { Component } from 'react';
import { Link } from "react-router-dom";


import './Announcements.css'

class Announcements extends Component {

    render() {
        return (
            <ul className='Announcements'>
                {this.props.announcements.map(announcement => <li>
                    <Link to={announcement.url}>
                       <img width='128' height='128' src={announcement.icon} alt={announcement.name} />
                       <div className="Announcements-title">
                            {announcement.name}<br/>
                            <time>{new Date(announcement.timestamp).toDateString()}</time>
                       </div>
                    </Link>
                </li>)}
            </ul>
        )
    }
}
export default Announcements