import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Gallery.css';
import { Carousel } from 'react-responsive-carousel';
 
class Gallery extends Component {
    render() {
        return (
            <Carousel> 
                {
                    this.props.images.map(image => {
                        return (
                            <div>
                                <img src={image} alt={image}/>
                            </div>
                        )
                    })
                }
            </Carousel>
        );
    }
};
 
export default Gallery;