import React from 'react';
import ReactMarkdown from 'react-markdown';
import './Page.css';
import Gallery from './Gallery';
import {Helmet} from "react-helmet";


class Page extends React.Component {

    state = {
        text : '',
        image : '',
        name : '',
        gallery : []
    }

    async componentDidMount () {
        const page = this.props.pages.find(page => page.url === this.props.path);
        
        const article = await fetch(page.article).then(r => r.text());

        this.setState({
            text: article,
            image : page.image,
            name : page.name,
            gallery : page.gallery,
            url : page.url
        })
    }

    render () {
        return(

            <div className='Page'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.name}</title>
                    <link rel="canonical" href={"http://theultimatecomment.com" + this.state.url} />
                    <meta name="description" content={this.state.name} />
                    <meta property="og:image" content={"http://theultimatecomment.com" + this.state.image}  />
                </Helmet>
                <img alt={this.state.name} src={this.state.image} className='Page-image' />
                <ReactMarkdown source={this.state.text} />
                { this.state.gallery.length ? ( 
                    <div>
                        <h2>Gallery <span className="Page-swipe-hint">(swipe to view)</span> </h2>
                        <Gallery images={this.state.gallery} />
                    </div>
                ) : ''}
               
            </div>
        )
    }
}

export default Page;